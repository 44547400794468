@media screen and (max-width: 768px) {

  .contact-container {
    flex-direction: column;
    height: 750px;
  }

  .label-container {
    width: 75%;
  }

  .contact-info {
    width: 80%;
  }


}