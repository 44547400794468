@import '../variables';

/* */

a, a:hover, a:focus, a:active {
  text-decoration: none;
}


.bio-intro {
  font-size: 48px;
}

.bio-intro-second {
  font-size: 1.4rem;
  font-weight: bold;
}

.bio-text {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.25;
}

.tech-icon {
  width: 100px; 
  height: auto;
  margin: 0 10px 0 10px;
}

.center-text {
  text-align: center;
}



.name {
  font-family: 'Prompt', sans-serif;
  font-size: 7em;
  line-height: 1;
  padding: 0;
  margin: 0;
  text-align: center;
  align-items: center;
}

.brush {
  margin: 0 50px 0 50px;
  background-color: inherit;
  border: none;
  cursor: pointer;
}

.introduction-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  // background-color: $magnolia;
}

.section-container {
  box-sizing: border-box;
  width: 90%;
  justify-content: center;
  border-bottom: 1px $black solid;
  padding: 4rem 0 4rem 0;
  display: flex;
  @include flex-middle(column);
  // background-color: $magnolia;
}

.name-container {
  width: auto;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon-container {
  @include flex-middle(row);
}

.home-name {
  width: 90%;
  height: 500px;
  margin-top: 100px;
}

.home-container {
  width: 100%; 
  height: auto;
}

.about {
  width: 60%;
  // justify-content: flex-start;
  font-size: 18px;
  @include flex-center(column);
}

.tagline {
  margin: 0px;
  text-align: center;
  line-height: 3;
}

.resume-button {
  // background-color: $black;
  // color: $white;
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  text-align: center;
  font-weight: 400;
  line-height: 1;
  font-size: 18px;
  padding: 10px 20px 10px 20px;
  margin: 5px 0 5px 0;
  width: 200px;
  height: 50px;
  border: none;
  text-decoration: none;
}

.resume-button:hover {
  background-color: #29f3c3;
  color: #000;
  cursor: pointer;
}


.social-button {
  border: none;
  background: none;
  background-color: $black;
  color: $white;
  font-size: 16px;
  margin: 15px;
  margin-right: 0px;
  padding: 14px 20px;
  font-family: 'Prompt', sans-serif;
  cursor: pointer;
  font-weight: 500;
  width: 125px;
}

.image-container {
  width: 100%;
  height: 500px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 60%;
}

.extra-top-margin {
  margin-top: 150px;
}


// Light and Dark mode styles

.light-background {
  background-color: $magnolia;
  color: $black
}

.dark-background {
  background-color: $grey;
  color: $white;
}

.light-mode-button {
  background-color: $black;
  color: $white;
}

.dark-mode-button {
  background-color: $magnolia;
  color: $black;
}

.accent-text-light {
  color: $darkGreen;
}

.accent-text-dark {
  color: $seaGreen;
}

/* Neo Morphism */

// .light-neo-shadows {

// }

// .dark-neo-shadows {
  
// }

.neo-light {
  border-radius: 50px;
  background: linear-gradient(145deg, #ffffff, #dddde6);
  box-shadow:  10px 10px 30px #d0d0d9,
              -10px -10px 30px #ffffff;
  color: $grey;
}

.neo-light:hover {
  border-radius: 50px;
  background: linear-gradient(145deg, #dddde6, #ffffff);
  box-shadow:  10px 10px 30px #d0d0d9,
              -10px -10px 30px #ffffff;
}

.neo-dark {
  border-radius: 50px;
  background: linear-gradient(145deg, $grey, $black);
  box-shadow:  10px 10px 30px $black,
              -10px -10px 30px $shadowGrey;
  color: $shadowGrey;
}

.neo-dark:hover {
  border-radius: 50px;
  background: linear-gradient(145deg, $black, $grey);
  box-shadow:  10px 10px 30px $black,
              -10px -10px 30px $shadowGrey;
  color: $shadowGrey;
}

.neo-dark:active {
  border-radius: 50px;
  background: linear-gradient(145deg, $black, $grey);
  box-shadow:  10px 10px 30px $shadowGrey,
              -10px -10px 30px $black;
  color: $shadowGrey;
}


.neo-text {
  color: $grey;
  text-shadow: -4px -4px 8px $shadowGrey,
  4px  4px 8px $black; 
}

.neo-text-light {
  color: #dddddd;
  text-shadow: 3px 3px 7px #b1b1b1,
  -3px -3px 7px #ffffff;
}

.neo-accent-text {
  color: $magnolia;
  text-shadow: 4px -4px -8px $shadowGrey,
  4px  -4px 8px $black; 
}

.neo-section-container {
  box-sizing: border-box;
  width: 90%;
  justify-content: center;
  border-bottom: 1px $black solid;
  padding: 50px 0 50px 0;
  display: flex;
  @include flex-middle(column);
  border-radius: 50px;
  background: $grey;
  box-shadow:  10px 10px 30px $lightGrey,
              -10px -10px 30px $magnolia;
  margin: 50px 50px 50px 50px;
}

.neo-section-container-inner {
  box-sizing: border-box;
  width: 90%;
  justify-content: center;
  border-bottom: 1px $black solid;
  padding: 0 0 50px 0;
  display: flex;
  @include flex-middle(column);
  border-radius: 50px;
  background: $grey;
  box-shadow: inset  5px 5px 15px $shadowGrey,
              inset -5px -5px 15px $white;
  margin-top: 50px;
  height: auto;
}

.neo-section-container-concave {
  box-sizing: border-box;
  width: 90%;
  justify-content: center;
  border-bottom: 1px $black solid;
  padding: 0 0 50px 0;
  display: flex;
  @include flex-middle(column);
  border-radius: 50px;
  box-shadow:  10px 10px 30px $shadowGrey,
              -10px -10px 30px $white;
  margin: 50px 0 50px 0;
}


/* Expiremental classes */
.light-flat-background {
  background-color: $magnolia;
}

.dark-flat-background {
  background-color: $grey;
}

.light-flat-text {
  color: $black; 
}

.dark-flat-text {
  color: $magnolia;
}




