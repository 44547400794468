@import '../variables';


.projects-outter {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 30px;
  background-color: $magnolia;
}

.portfolio-title {
  margin: 150px 0 50px 0;
}


.portfolio-container {
  /* border: 2px red solid; */
  width: 90%; 
  height: auto;
  display: flex;
  flex-direction: column;
}


// Light and Dark mode styles 
.light-background {
  background-color: $magnolia;
  color: $black
}

.dark-background {
  background-color: $grey;
  color: $white;
}

.light-mode-button {
  background-color: $black;
  color: $white;
}

.dark-mode-button {
  background-color: $magnolia;
  color: $black;
}

.accent-text-light {
  color: $black;
}

.accent-text-dark {
  color: $seaGreen;
}


@media screen and (min-width: 1500px) {
  .portfolio-container {
    height: auto;
  }
}