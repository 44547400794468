@media screen and (max-width: 1350px) {

  .nav-button {
    width: 100px;
  }

  .nav-button {
    width: 100px;
  }
 

}