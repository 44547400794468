@import '../variables';


.footer-container {
  color: $white;
  background-color: $black;
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-info-container {
  width: 80%;
  height: 80%;
  font-family: 'Prompt', sans-serif;
  font-size: 30px;
  font-weight: 600;
  margin: 0;
  padding-bottom: 10px;
}

.contact-label-container {
  padding: 10px 0px 10px 0px;
  font-family: 'Prompt', sans-serif;
  font-size: 30px;
  font-weight: 600;
  margin: 0;
  padding: 20px 0 10px;
}

.contact-value-container {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  padding: 10px 0px 10px;
  font-weight: 400;
}

.meeting-container {
  display: flex;
  flex-direction: column;
}

.meeting-value {
  color: white;
  text-decoration: none;
  padding: 10px 0px 10px;

}

.social-logo {
  height: 30px; 
  width: 30px;
  padding: 10px 20px 10px 0px
}