@media screen and (max-width: 768px) {
  .name-container {
      width: 90%;
      height: 350px;
  }

  .name {
      font-size: 3em;
  }

  .extra-top-margin {
      margin-top: 50px;
  }

  .section-container {
      padding: 0;
  }

  .bio-intro {
      margin-top: 0;
      font-size: 36px;
  }

  h3 {
    margin-bottom: .25em
  }

  .about {
    width: 80%;
  }

  .tech-icon {
    width: 35px;
  }


  .section-container{
    padding: 150px 0 150px 0;
  }

}