@import '../variables';

a, a:hover, a:focus, a:active {
  text-decoration: none;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.neo-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
}



.nav-button {
  color: #fff;
  background-color: inherit;
  font-family: Prompt, sans-serif;
  border: none;
  font-size: 16px;
  margin: 2em;
  font-weight: 300;
  width: 120px;
  height: 75px;
  cursor: pointer;
  text-decoration: none;

}

.nav-button:hover {
  background-color: #29f3c3;
  color: $black;
}

.home-button {
  height: 5rem;
  border: none;
  outline: none;
  align-self: center;
  padding-left: 25px;
  display: flex;
}

.nav-bar {
  width: 100%; 
  height: 5rem;
  padding: 5px 0 5px 0;
  background-color: $black;
  display: flex;
  align-items: center;
  transition: all .2s linear;
  outline: none;
  text-decoration: none;



}

.nav-bar-active {
  width: 100%; 
  height: 5rem;
  padding: 5px 0 5px 0;
  background-color: rgba($color: #000000, $alpha: .8);
  display: flex;
  align-items: center;
  transition: all .2s ease-out;
}

.neo-nav-bar {
  width: 100%; 
  height: 100px;
  padding: 5px 0 5px 0;
  background-color: inherit;
  display: flex;
  align-items: center;
}

/* .nav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
} */


.button-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.logo-contaner {
  width: 25%;
}

.header {
  width: 100%;
}

.header {
  position: fixed;
}

.header-neo {
  position: absolute;
}






//boring layout stuff:
.switch-html { box-sizing: border-box; height: 100% } *, *::before, *::after { box-sizing: inherit; }
// .switch-body {display: flex; align-items: center; justify-content: center; min-height: 100vh; background: #f5f5f5; }

//toggle code begins!

.switch-wrap {
  align-self: center;
  cursor: pointer;
  background: $darkGreen;
  padding: $padding;
  width: $width;
  height: $height;
  border-radius: $height / 2;
  input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
}
.switch {
  height: 100%;
  display: grid;
  grid-template-columns: 0fr 1fr 1fr;
  transition: .2s;
  //ICYMI, pseudo elements are treated as grid items
  &::after {
    content: '';
    border-radius: 50%;
    background: $white;
    grid-column: 2;
    transition: background .2s;
  }
}
input:checked {
  + .switch {
    grid-template-columns: 1fr 1fr 0fr; 
    &::after {
      background-color: $seaGreen;
    }
  }
}