@media screen and (max-width: 768px) {

  .portfolio-container {

    height: auto;
  }





}