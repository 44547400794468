@import '../variables';


.app {
  width: 100%;
  height: auto;
}



