@import '../variables';


.contact-container {
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: row;
  padding: 150px 0 100px 0;
}

.neo-contact-container {
  width: 90%;
  height: 500px;
  display: flex;
  flex-direction: row;
  padding: 150px 0 100px 0;
  border-radius: 50px;
  background: $grey;
  box-shadow: inset  5px 5px 15px $shadowGrey,
              inset -5px -5px 15px $white;
  margin: 100px 100px 100px 100px;
}



.contact-name {
  font-family: 'Prompt', sans-serif;
  font-size: 48px;
  font-weight: 600;
  line-height: 1;
  width: 50%;
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.contact-info {
  font-family: 'Prompt', sans-serif;
  font-weight: 600;
  line-height: 1;
  width: 50%;
  margin-top: 50px;
  display: flex;
  text-align: start;
  padding-left: 50px;
  flex-direction: column;
}

.label-container {
  border-bottom: 1px solid #000;
  width: 50%;
  height: 30px;
  font-size: 30px;
  padding: 10px 0 10px 0;
}

.contact-container {
  font-size: 18px;
  font-weight: 200;
  display: flex;
  // background-color: $magnolia;
}

.contact {
  width: 100%;
  height: auto;
  font-weight: 300;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.email {
  padding: 20px 0 20px 0;
}


li {
  text-decoration: none;
}

ul, li {
  list-style: none;
  list-style-position: inside;
  margin: 0;
  padding: 10px 0 10px 0;
  text-decoration: none;
}

ul:active, li:active {
  list-style: none;
  list-style-position: inside;
  margin: 0;
  padding: 10px 0 10px 0;
  text-decoration: none;
}

.contact-image-container {
  width: 100%;
  height: 500px;
}

.neo-contact-image-container {
  width: 100%;
  height: 500px;
  display: flex;
  border-radius: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-self: center;
}

.neo-contact-image {
  width: 95%;
  height: 95%;
  object-fit: cover;
  object-position: 50% 50%;
  border-radius: 100%;

}

.contact-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

// Light and Dark mode styles 
.light-background {
  background-color: $magnolia;
  color: $black
}

.dark-background {
  background-color: $grey;
  color: $white;
}

.light-mode-button {
  background-color: $black;
  color: $white;
}

.dark-mode-button {
  background-color: $magnolia;
  color: $black;
}


.light-neo-box-shadow {
  box-shadow:  10px 10px 30px $lightGrey,
  -10px -10px 30px $magnolia;
}

.dark-neo-box-shadow {
  box-shadow:  10px 10px 30px $shadowGrey,
                -10px -10px 30px $black;
}

.accent-text-light {
  color: $darkGreen;
}

.accent-text-dark {
  color: $seaGreen;
}

.neo-text {
  color: $grey;
  text-shadow: -4px -4px 8px $shadowGrey,
  4px  4px 8px $black; 
}

.neo-text-light {
  color: #dddddd;
  text-shadow: 3px 3px 7px #b1b1b1,
  -3px -3px 7px #ffffff;
}

.neo-accent-text {
  color: $magnolia;
  text-shadow: 4px -4px -8px $shadowGrey,
  4px  -4px 8px $black; 
}