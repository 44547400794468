@import '../variables';


.project-description-container {
  /* border: 2px blue dotted; */
  width: 45%;
  height: 100%;
}

.project-button {
  border: none;
  // background-color: $black;
  // color: $white;
  width: 150px;
  height: 50px;
  margin: 0 25px 0 25px;
  cursor: pointer;
  font-size: 16px;
}

.project-button:hover {
  background-color: $seaGreen;
  color: $black;
  cursor: pointer;
}

.project-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.project-images-container {
  /* border: 2px pink dotted; */
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}



.preview-photo {
  width: 100%
}

.project-thumbnail-container {
  width: 40%;
  padding: 20px 10px 20px 10px;
  height: 100px;
}

.thumbnail {
  width: 100%;
  cursor: pointer;
  margin: 10px 0 10px 0;
}

.photo-display {
  width: 100%;
}


.project-outter-container {
  width: 100%;
  height: 800px;
  /* border: 2px green solid; */
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 4rem 0 4rem 0;
}

.neo-project-outter-container {
  width: 100%;
  height: 125vh;
  /* border: 2px green solid; */
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 50px 0 50px 0;
  margin: 50px 0 50px 0;

  border-radius: 50px;
  background: $grey;
  box-shadow: inset  5px 5px 15px $shadowGrey,
              inset -5px -5px 15px $white;
}

.project-divider {
  border-top: 1px solid $black;
}

.project-title {
  font-size: 32px;
  margin: 10px 0 10px 0;
  font-weight: 700;
}

.label {
  font-size: 16px;
  font-weight: bold;
}

.value {
  font-size: 16px;
  margin: 0 0 0 10px;
  font-weight: normal;
}

.warning {
  font-size: 16px;
  font-style: italic;
  margin-bottom: 50px;
}

.tech {
  // background-color: $black;
  // color: $white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 400;
  line-height: 1;
  font-size: 14px;
  padding: 10px 20px 10px 20px;
  margin: 5px 0 5px 0;
  width: 50px;
}




.tech-stack-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

// Light and Dark mode styles 
.light-background {
  background-color: $magnolia;
  color: $black
}

.dark-background {
  background-color: $grey;
  color: $white;
}

.light-mode-button {
  background-color: $black;
  color: $white;
}

.dark-mode-button {
  background-color: $magnolia;
  color: $black;
}

.accent-text-light {
  color: $black;
}

.accent-text-dark {
  color: $seaGreen;
}

@media screen and (min-width: 1500px) {
  .project-thumbnail-container {
    display: none;
  }


  .project-outter-container {
    height: 800px;
    margin-bottom: 4rem;
  }

  .project-images-container {
    /* border: 2px pink dotted; */
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .project-title {
    font-size: 1.5em;
    margin-bottom: 25px;
  }
}

  @media screen and (min-width: 2500px) {
    .project-thumbnail-container {
      display: none;
    }
  
    .label {
      font-size: 1em;
    }
  
    .value {
      font-size: 1em;
    }  
  
    .project-outter-container {
      height: 1250px;
      margin-bottom: 4rem;
    }
  
    .project-images-container {
      /* border: 2px pink dotted; */
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .project-title {
      font-size: 2.5em;
      margin-bottom: 25px;
    }
  
  }