@media screen and (max-width: 1350px) {


  .label-container {
    width: 75%;
  }

  .contact-name {
    padding-left: 50px;
    line-height: 1.25em;
  }


}